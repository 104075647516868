import * as React from 'react';

export const bridgeLogo: JSX.Element = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="6" fill="#C2EAFF" />
    <path
      d="M10.2109 10.6328H12.1874V17.6916H10.2109V10.6328Z"
      stroke="#0572EC"
    />
    <path
      d="M19.8125 10.6328H21.789V17.6916H19.8125V10.6328Z"
      stroke="#0572EC"
    />
    <path
      d="M14.7295 10.2109H17.2707V13.0318C17.2707 13.3101 17.1909 13.5826 17.0409 13.8169V13.8169C16.8367 14.136 16.8367 14.5447 17.0409 14.8638V14.8638C17.1909 15.0981 17.2707 15.3706 17.2707 15.6489V17.2698H14.7295V13.7403V13.4038C14.7295 13.153 14.8133 12.9094 14.9676 12.7118V12.7118C15.1633 12.461 15.1795 12.1141 15.008 11.8462L14.9456 11.7486C14.8045 11.5281 14.7295 11.2718 14.7295 11.01V10.2109Z"
      stroke="#0572EC"
    />
    <path
      d="M6.82324 20.8742C6.82324 20.6375 6.86626 20.4042 6.98592 20.2C7.54858 19.2398 9.73361 16.4219 15.9997 16.4219C22.2491 16.4219 24.4392 19.2247 25.009 20.1922C25.1321 20.4013 25.1762 20.6406 25.1762 20.8832C25.1762 21.4259 23.8973 21.8205 23.495 21.4563C22.1555 20.2436 19.8468 18.999 15.9997 18.9631C12.1287 18.9269 9.81513 20.1886 8.47945 21.4274C8.08953 21.789 6.82324 21.406 6.82324 20.8742Z"
      fill="#0572EC"
    />
    <path
      d="M5.12891 12.3281H7.67008V22.6222C7.67008 23.1745 7.22237 23.6222 6.67008 23.6222H6.12891C5.57662 23.6222 5.12891 23.1745 5.12891 22.6222V12.3281Z"
      fill="#0572EC"
    />
    <path
      d="M24.3301 12.3281H26.8713V22.6222C26.8713 23.1745 26.4235 23.6222 25.8713 23.6222H25.3301C24.7778 23.6222 24.3301 23.1745 24.3301 22.6222V12.3281Z"
      fill="#0572EC"
    />
    <path
      d="M4 13.0741C4 12.6006 4.20364 12.1524 4.58773 11.8754C6.06533 10.8099 10.2334 8.23438 16 8.23438C21.7666 8.23438 25.9347 10.8099 27.4123 11.8754C27.7964 12.1524 28 12.6006 28 13.0741C28 13.7747 27.1408 14.1938 26.5553 13.8091C24.5842 12.5138 20.7484 10.4932 16 10.4932C11.2516 10.4932 7.41578 12.5138 5.44467 13.8091C4.85921 14.1938 4 13.7747 4 13.0741Z"
      fill="#0572EC"
    />
  </svg>
);
