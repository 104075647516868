import * as React from 'react';

export const trash: JSX.Element = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" fill="black" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0C4.89543 0 4 0.89543 4 2V3H2C1.44772 3 1 3.44772 1 4C1 4.55228 1.44772 5 2 5L2 13C2 14.6569 3.34315 16 5 16H11C12.6569 16 14 14.6569 14 13V5C14.5523 5 15 4.55228 15 4C15 3.44772 14.5523 3 14 3H12V2C12 0.89543 11.1046 0 10 0H6ZM10 3V2H6V3H10ZM5 5H4V13C4 13.5523 4.44772 14 5 14H11C11.5523 14 12 13.5523 12 13V5H11H5ZM6 7.5C6 7.22386 6.22386 7 6.5 7C6.77614 7 7 7.22386 7 7.5V11.5C7 11.7761 6.77614 12 6.5 12C6.22386 12 6 11.7761 6 11.5V7.5ZM9.5 7C9.22386 7 9 7.22386 9 7.5V11.5C9 11.7761 9.22386 12 9.5 12C9.77614 12 10 11.7761 10 11.5V7.5C10 7.22386 9.77614 7 9.5 7Z"
      fill="black"
      fillOpacity="0.55"
    />
  </svg>
);
