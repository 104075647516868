import * as React from 'react';

export const search: JSX.Element = (
  <svg
    width="17"
    height="17"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" fill="black" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13C8.22174 13 9.35819 12.6348 10.3062 12.0077L12.3957 14.6196C12.9848 15.356 14.0832 15.4168 14.75 14.75C15.4168 14.0832 15.356 12.9848 14.6196 12.3957L12.0077 10.3062C12.6348 9.35819 13 8.22174 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13ZM11 7C11 9.20914 9.20914 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3C9.20914 3 11 4.79086 11 7Z"
      fill="#333333"
    />
  </svg>
);
