import * as React from 'react';

export const provisioningLogo: JSX.Element = (
  <svg
    width="219"
    height="378"
    viewBox="0 0 219 378"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M102 0H211C215.418 0 219 3.58172 219 8V370C219 374.418 215.418 378 211 378H102V0Z"
      fill="#F1FAFF"
    />
    <mask
      id="mask0_5_3"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="219"
      height="378"
    >
      <path
        d="M0 0H211C215.418 0 219 3.58172 219 8V370C219 374.418 215.418 378 211 378H0V0Z"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0_5_3)">
      <mask
        id="mask1_5_3"
        maskUnits="userSpaceOnUse"
        x="26"
        y="19"
        width="216"
        height="329"
      >
        <rect x="26" y="19.5" width="215.484" height="328.17" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask1_5_3)">
        <path
          d="M252.536 225.422L160.984 280.329C158.733 281.719 155.935 281.719 153.624 280.329L135.435 269.398C133.184 268.071 130.386 268.008 128.135 269.398L41.146 320.957C38.7128 322.41 35.6104 320.578 35.6104 317.671V289.112C35.6104 286.458 38.4086 284.752 40.6594 286.079L129.899 338.964C132.88 340.733 136.591 340.733 139.571 338.964C161.836 325.506 252.536 271.041 252.536 271.041"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M140.496 235.153C139.802 230.701 141.946 228.147 145.287 229.522C148.628 230.832 151.843 235.546 152.537 239.999C153.23 244.452 151.087 247.006 147.745 245.631C146.926 245.303 146.106 244.779 145.287 244.059"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M135.767 233.321C134.57 225.333 138.415 220.815 144.341 223.172C150.267 225.529 156.13 233.911 157.328 241.899C158.526 249.888 154.68 254.406 148.754 252.049C146.359 251.067 143.963 249.168 141.883 246.68"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M129.841 230.831C127.95 218.521 133.939 211.515 143.143 215.182C152.347 218.848 161.363 231.814 163.191 244.19C165.082 256.5 159.093 263.507 149.889 259.84C145.98 258.268 142.071 254.994 138.73 250.803"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.1662 230.786C44.1036 230.315 44.1992 228.507 43.3797 226.748C42.5601 224.989 41.1358 223.945 40.1984 224.416C39.2609 224.888 39.1653 226.695 39.9849 228.454C40.8044 230.213 42.2287 231.257 43.1662 230.786Z"
          fill="#0572EC"
        />
        <path
          d="M49.6251 235.263C50.5626 234.791 50.6581 232.983 49.8386 231.224C49.0191 229.466 47.5948 228.422 46.6573 228.893C45.7199 229.364 45.6243 231.172 46.4438 232.931C47.2634 234.69 48.6877 235.734 49.6251 235.263Z"
          fill="#66CBFF"
        />
        <path
          d="M35.6104 231.332C35.6104 232.027 35.9145 232.722 36.5837 233.101L223.401 343.936"
          stroke="#0572EC"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.2429 194.052L36.5838 212.439C35.2456 213.197 35.2456 215.219 36.5838 215.977L74.7861 238.85C76.4893 239.861 78.5576 239.861 80.2609 238.85L117.855 216.293C119.436 215.345 119.436 213.007 117.855 212.059L34.6372 161.828C32.1431 160.311 30.5615 157.531 30.5615 154.498V74.3806C30.5615 70.5263 34.5764 68.1253 37.8005 70.084L81.273 95.8124"
          stroke="#0572EC"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.6104 214.208V231.33"
          stroke="#0572EC"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M233.07 146.223L168.954 184.702C166.399 186.218 164.817 189.062 164.817 192.095V223.434C164.817 226.277 163.357 228.931 160.924 230.384L107.757 261.913C105.02 263.556 101.674 263.556 98.9367 261.913L59.8828 238.598"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M86.6159 87.074C85.229 90.4953 86.327 94.2768 90.0832 97.458C97.0755 103.34 110.771 104.961 120.595 100.999C128.974 97.638 131.921 91.3957 128.223 85.9336"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.5654 107.483C80.4322 108.863 81.6458 110.244 83.1483 111.504C92.5677 119.427 110.886 121.528 124.062 116.306C131.17 113.485 135.331 109.103 136.197 104.421"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M83.8417 122.008C84.593 123.389 85.7487 124.769 87.2512 126.03C95.2837 132.812 110.944 134.613 122.271 130.111C126.778 128.311 129.899 125.79 131.517 122.969"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M107.94 135.993C123.611 135.993 136.314 122.798 136.314 106.522C136.314 90.2455 123.611 77.0508 107.94 77.0508C92.2698 77.0508 79.5664 90.2455 79.5664 106.522C79.5664 122.798 92.2698 135.993 107.94 135.993Z"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M107.65 82.3945C107.65 82.3945 104.356 92.9585 103.374 114.747C102.796 126.691 104.241 135.755 104.241 135.755"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M85.0559 123.63C85.0559 123.63 80.8374 113.246 87.5985 100.701C94.3597 88.1567 107.651 82.3945 107.651 82.3945"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M107.65 82.3908C107.65 82.3908 96.4391 79.6298 86.6152 87.1326"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.756 77.7695C101.756 77.7695 105.685 80.0504 107.65 82.3313"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M120.942 80.3521C120.942 80.3521 114.354 78.2513 107.65 82.3929"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M121.519 132.454C121.519 132.454 128.743 122.79 122.791 105.623C117.012 88.937 107.708 82.3945 107.708 82.3945"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M135.966 102.979C135.966 102.979 133.597 92.9551 121.519 85.9324C112.158 80.5304 107.708 82.3311 107.708 82.3311"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.2296 82.5672C40.1671 82.096 40.2626 80.2881 39.4431 78.5292C38.6236 76.7703 37.1993 75.7263 36.2618 76.1976C35.3244 76.6688 35.2288 78.4767 36.0483 80.2356C36.8679 81.9946 38.2922 83.0385 39.2296 82.5672Z"
          fill="#66CBFF"
        />
        <path
          d="M46.881 87.1805C47.8185 86.7093 47.9141 84.9014 47.0945 83.1425C46.275 81.3835 44.8507 80.3396 43.9132 80.8108C42.9758 81.2821 42.8802 83.09 43.6997 84.8489C44.5193 86.6078 45.9436 87.6517 46.881 87.1805Z"
          fill="#66CBFF"
        />
        <path
          d="M157.546 129.502V88.3689C157.546 85.0201 159.249 81.9873 162.047 80.3445L230.057 39.5273"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M149.2 58.104C149.547 56.8784 149.755 55.5086 149.755 53.8505C149.755 46.7132 145.73 38.4946 140.732 35.4667C135.735 32.5109 131.709 35.8993 131.709 43.0365C131.709 44.6225 131.917 46.3528 132.334 48.0109V48.083C132.611 49.2365 132.958 50.4621 133.375 51.5435L140.593 72.8831L148.089 60.2668C148.575 59.7621 148.922 59.0412 149.2 58.104Z"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M143.379 53.2897C145.035 52.4572 145.227 49.3129 143.808 46.2666C142.388 43.2203 139.895 41.4257 138.239 42.2582C136.583 43.0907 136.391 46.2351 137.81 49.2814C139.229 52.3276 141.723 54.1222 143.379 53.2897Z"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.5637 168.603C84.5637 162.324 88.1134 159.328 92.4951 161.978C92.9388 162.209 93.327 162.554 93.7153 162.9C95.4901 158.752 99.2063 157.369 103.532 159.962C109.079 163.303 113.682 172.06 114.348 180.24C117.288 182.66 119.562 187.73 119.562 192.166C119.562 196.89 117.01 199.194 113.793 197.581V197.639L92.4396 184.849L53.67 161.46"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M97.0431 152.298C94.9909 148.092 92.1068 144.521 88.9453 142.619C84.4527 139.912 80.5147 141.352 78.6844 145.673C78.2407 145.327 77.8524 144.981 77.4087 144.751C72.8607 141.986 69.1445 145.097 69.1445 151.722C69.1445 158.289 72.8607 165.894 77.4087 168.601L99.8163 182.025V181.967"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M145.187 150.071C139.712 146.786 135.332 145.332 135.332 146.786V157.274C135.332 164.288 137.948 172.249 142.206 178.188L145.187 182.422L148.167 181.79C152.426 180.905 155.041 176.04 155.041 169.09V158.601C155.041 157.211 150.662 153.357 145.187 150.071Z"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.0254 302.004L131.967 326.451"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M213.273 132.453L171.332 156.9"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.7109 317.984L81.1277 326.662"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M180.045 122.743V104.033L190.786 97.5899V116.388L197.351 112.417L185.415 135.187L173.48 126.714L180.045 122.743Z"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M243.982 157.641L181.19 196.984C178.278 198.762 178.353 203.167 181.339 204.868L266.755 253.796"
          stroke="#66CBFF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
