import * as React from 'react';

export const folder: JSX.Element = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_496_517167)">
      <rect width="16" height="16" fill="black" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5C2.44772 5 2 5.44772 2 6V12C2 12.5523 2.44772 13 3 13H13C13.5523 13 14 12.5523 14 12V6C14 5.44772 13.5523 5 13 5C9.41108 5 6.38902 5 3 5ZM0 4C0 2.34315 1.34315 1 3 1H6.17157C6.96722 1 7.73028 1.31607 8.29289 1.87868L9.12132 2.70711C9.30886 2.89464 9.56321 3 9.82843 3H13C14.6569 3 16 4.34315 16 6V12C16 13.6569 14.6569 15 13 15H3C1.34315 15 0 13.6569 0 12V4Z"
        fill="#0364D3"
      />
    </g>
    <defs>
      <clipPath id="clip0_496_517167">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
