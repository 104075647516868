import * as React from 'react';

export const people: JSX.Element = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" fill="black" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.59987 7.86263C9.98557 7.31124 10.2629 6.67844 10.4009 5.99515C10.4335 5.99836 10.4665 6 10.5 6C11.0522 6 11.5 5.55228 11.5 5C11.5 4.44772 11.0522 4 10.5 4C10.4665 4 10.4335 4.00164 10.4009 4.00485C10.2629 3.32156 9.98557 2.68876 9.59987 2.13737C9.884 2.04812 10.1864 2 10.5 2C12.1568 2 13.5 3.34315 13.5 5C13.5 6.65685 12.1568 8 10.5 8C10.1864 8 9.884 7.95188 9.59987 7.86263ZM13.0284 12.7466C13.1637 11.9349 12.6232 11.1878 11.8516 11.0304C11.6432 10.2804 11.2782 9.58978 10.7915 9H11.5489C13.7116 9 15.3568 10.942 15.0012 13.0754L14.9864 13.1644C14.8956 13.7092 14.3804 14.0772 13.8356 13.9864C13.2908 13.8956 12.9228 13.3804 13.0136 12.8356L13.0284 12.7466ZM4.45106 11C3.52415 11 2.81909 11.8323 2.97147 12.7466L2.9863 12.8356C3.0771 13.3804 2.70908 13.8956 2.16431 13.9864C1.61954 14.0772 1.10431 13.7092 1.01352 13.1644L0.998683 13.0754C0.643123 10.942 2.28827 9 4.45106 9H6.54876C8.71155 9 10.3567 10.942 10.0011 13.0754L9.9863 13.1644C9.89551 13.7092 9.38028 14.0772 8.83551 13.9864C8.29074 13.8956 7.92272 13.3804 8.01352 12.8356L8.02835 12.7466C8.18073 11.8323 7.47567 11 6.54876 11H4.45106ZM8.5 5C8.5 6.65685 7.15685 8 5.5 8C3.84315 8 2.5 6.65685 2.5 5C2.5 3.34315 3.84315 2 5.5 2C7.15685 2 8.5 3.34315 8.5 5ZM4.5 5C4.5 5.55228 4.94772 6 5.5 6C6.05228 6 6.5 5.55228 6.5 5C6.5 4.44772 6.05228 4 5.5 4C4.94772 4 4.5 4.44772 4.5 5Z"
      fill="#0364D3"
    />
  </svg>
);
